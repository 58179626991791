import { Observable, Observer } from 'rxjs';
import * as screenFullLib from 'screenfull';

const screenFull: screenFullLib.Screenfull = screenFullLib as any;

export interface IFullScreenState {
  isFullScreen: boolean;
  byAPI: boolean;
}

export function openNewWindow(url: string, fullScreen?: boolean) {
  window.open(url, '_blank', fullScreen ? 'fullscreen=yes, scrollbars=auto' : undefined);
}

export function toggleFullScreen(element: Element) {
  if (screenFull && fullScreenEnabled()) {

    screenFull.toggle(element).then(() => {
      console.log('Full screen toggle completed.');
    });
  }
}

export function fullScreenEnabled(): boolean {
  return screenFull.isEnabled;
}

export function fullScreenChanged(): Observable<IFullScreenState> {
  let isFull = screenFull && screenFull.isFullscreen;
  return new Observable((observer: Observer<IFullScreenState>) => {
    const notifyFullScreeChange = (isFullScreen: boolean, byAPI: boolean) => {
      if (isFullScreen !== isFull) {
        isFull = isFullScreen;
        observer.next({
          isFullScreen,
          byAPI
        });
      }
    };
    window.addEventListener('resize', () => {
      const isFullScreen = isWindowFullScreen(); // disable this detection for Edge
      notifyFullScreeChange(isFullScreen, screenFull && (isFullScreen === screenFull.isFullscreen));
    });
    if (screenFull) {
      screenFull.on('change', () => {
        notifyFullScreeChange(screenFull && screenFull.isFullscreen, true);
      });
    }
  });
}

export function isWindowFullScreen(): boolean {
  return (screenFull && screenFull.isFullscreen) || (screen.width === window.innerWidth && screen.height === window.innerHeight && window.screenX === window.screenLeft && window.screenY === window.screenTop);
}

export function fullScreenError(): Observable<Event> {
  return new Observable((observer: Observer<Event>) => {
    if (screenFull) {
      screenFull.on('error', (e: Event) => {
        observer.next(e);
      });
    }
  });
}
