import { InjectionToken } from '@angular/core';

export interface ICoreModuleConfig {
    clientConfigKey?: string;
}

export const CORE_CONFIGURATION = new InjectionToken<ICoreModuleConfig>(
    'CORE_CONFIGURATION'
);
export const CORE_CONFIGURATION_OPTIONS = new InjectionToken<ICoreModuleConfig>(
    'CORE_CONFIGURATION_OPTIONS'
);
