// easing functions http://goo.gl/5HLl8
export function easeInOutQuad(t: number, b: number, c: number, d: number) {
  t /= d / 2;
  if (t < 1) {
    return c / 2 * t * t + b;
  }
  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
}

export function easeInCubic(t: number, b: number, c: number, d: number) {
  const tc = (t /= d) * t * t;
  return b + c * (tc);
}

export function cubicInOut(t: number, b: number, c: number, d: number) {
  const ts = (t /= d) * t;
  const tc = ts * t;
  return b + c * (-3 * tc * ts + 7.3 * ts * ts + -7.6 * tc + 4.3 * ts);
}

export function inOutQuintic(t: number, b: number, c: number, d: number) {
  const ts = (t /= d) * t;
  const tc = ts * t;
  return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
}

export function linear(t: number, b: number, c: number, d: number) {
  return b + c * (t / d);
}
