import { Observable, Observer } from 'rxjs';

// Gets blob from canvas element. Used 'image/jpg' at 80% quality by default
export function getCanvasBlob(canvas: any, imgType = 'image/jpeg', quality = 80): Observable<Blob> {
  return new Observable((observer: Observer<any>) => {
    if (!HTMLCanvasElement.prototype.toBlob) {
      const binStr = atob(canvas.toDataURL(imgType, quality).split(',')[1]);
      const len = binStr.length;
      const arr = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }

      observer.next(new Blob([arr], {type: imgType}));
      observer.complete();
    } else {
      canvas.toBlob((blob: any) => {
        observer.next(blob);
        observer.complete();
      }, imgType, quality);
    }
  });
}

