import { AbstractControl, FormGroup } from '@angular/forms';

export function validateFormGroup(formGroup: FormGroup) {
  for (const prop in formGroup.controls) {
    if (formGroup.controls.hasOwnProperty(prop)) {
      const item = formGroup.controls[prop];
      if (item instanceof FormGroup) {
        validateFormGroup(item);
      } else {
        // mask as 'touched' to show validation errors
        item.markAsTouched();
      }
    }
  }
  formGroup.updateValueAndValidity();
}

export function clearFormGroup(formGroup: FormGroup) {
  for (const prop in formGroup.controls) {
    if (formGroup.controls.hasOwnProperty(prop)) {
      const item = formGroup.controls[prop];
      if (item instanceof FormGroup) {
        clearFormGroup(item);
      } else {
        // clear 'touched' property to hide validation errors
        item.markAsUntouched();
        item.markAsPristine();
      }
    }
  }
}

export function getGroupControlValue(
  formGroup: FormGroup,
  controlName: string
): any {
  return formGroup.controls.hasOwnProperty(controlName) &&
  formGroup.controls[controlName]
    ? formGroup.controls[controlName].value
    : null;
}

export function getFormControlErrors(control: AbstractControl): Array<any> {
  if (control.touched && control.errors) {
    return Object.keys(control.errors);
  }
  return [];
}
