import { easeInCubic } from './mathHelper';

declare const window: any;
const requestAnimationFrame = (() => {
  // tslint:disable-next-line:no-string-literal
  return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window['mozRequestAnimationFrame'] || ((callback: any) => {
    window.setTimeout(callback, 1000 / 60);
  });
})();

const getStyle = (nativeEl: any, cssProp: string): any => {
  // IE
  if (nativeEl.currentStyle) {
    return nativeEl.currentStyle[cssProp];
  }

  if (window.getComputedStyle) {
    return window.getComputedStyle(nativeEl)[cssProp];
  }
  // finally try and get inline style
  return nativeEl.style[cssProp];
};

/**
 * Checks if a given element is statically positioned
 * @param nativeEl - raw DOM element
 */
const isStaticPositioned = (nativeEl: any): any => {
  return (getStyle(nativeEl, 'position') || 'static') === 'static';
};


/**
 * returns the closest, non-statically positioned parentOffset of a given element
 * @param nativeEl
 */
const parentOffsetEl = (nativeEl: any) => {
  let offsetParent = nativeEl.offsetParent || document;
  while (offsetParent && offsetParent !== document &&
  isStaticPositioned(offsetParent)) {
    offsetParent = offsetParent.offsetParent;
  }
  return offsetParent || document;
};

const raiseCallback = (callback: any, value?: any) => {
  if (callback && typeof (callback) === 'function') {
    // the animation is done so lets callback
    callback(value);
  }
};

export interface IElementPositionInfo {
  width: number;
  height: number;
  top: number;
  left: number;
}

export function getParentScroll(el: any): HTMLElement | undefined {
  const isScrollParent = isScrollable(el);
  if (isScrollParent) {
    return el;
  }
  if (el.parentNode) {
    return getParentScroll(el.parentNode);
  }
  return undefined;
}

export function isScrollable(el: any): any {
  return (/(auto|scroll)/).test(getCssValue(el, 'overflow') + getCssValue(el, 'overflow-y'));
}

export function scrollToWithAnimation(elementToScroll: any, to: number, scrollSpeed: number, easing: any, callback?: any): any {
  let from = elementToScroll.scrollTop;
  if (from === to) {
    // do not animate
    raiseCallback(callback);
    return;
  }
  // console.log(`Scroll animation started - from: ${from}, to: ${to}`);
  const duration = scrollSpeed;
  let currentTime = 0;
  let increment = 20;
  if (from > to) {
    // swap from & to
    from += to;
    to = from - to;
    from -= to;
    increment = -increment;
    currentTime = duration;
  }
  const change = to - from;
  let cancel = false;
  const canceler = () => {
    cancel = true;
  };
  const animateScroll = () => {
    // increment the time
    currentTime += increment;
    // move the document.body
    elementToScroll.scrollTop = duration === 0 ? to : easing(currentTime, from, change, duration);
    // do the animation unless its over
    if (!cancel && ((increment > 0 && currentTime < duration) || (increment < 0 && currentTime > 0))) {
      requestAnimationFrame(animateScroll);
    } else {
      // console.log('Scroll animation completed.');
      raiseCallback(callback, !cancel);
    }
  };
  animateScroll();
  return canceler;
}

export function getCssValue(element: any, property: string): any {
  let result: any = '';
  if (typeof window.getComputedStyle !== 'undefined') {
    try {
      result = window.getComputedStyle(element, null).getPropertyValue(property);
    } catch {
      // don't throw exception, just return empty value
    }
  } else if (typeof element.currentStyle !== 'undefined') {
    result = element.currentStyle[property];
  }
  return result;
}

export function getCssNumber(element: any, property: string): number {
  return parseInt(getCssValue(element, property), 10) || 0;
}


export function getViewportHeight(el: any) {
  const elH = el.offsetHeight;
  const H = window.innerHeight;
  const r = el.getBoundingClientRect();
  const t = r.top;
  const b = r.bottom;
  return Math.max(0, t > 0 ? Math.min(elH, H - t) : (b < H ? b : H));
}

export function closestParent(el: any, selector: string) {
  return el.closest(selector);
}

export function isDescendant(parent: any, child: any): boolean {
  let node = child.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}


export function scrollToViewportWhenNeeded(elementToScroll: any, options?: {offset?: number, duration?: number}, callback?: any): any {
  const scrollerEl = getParentScroll(elementToScroll);
  if (scrollerEl) {
    const scrollerBounds = scrollerEl.getBoundingClientRect();
    const elBounds = elementToScroll.getBoundingClientRect();
    const opt = {
      offset: 0,
      duration: 500,
      ...options
    };
    const isTopOut = elBounds.top - opt.offset < scrollerBounds.top;
    const isBottomOut = elBounds.top + elementToScroll.offsetHeight + opt.offset > scrollerBounds.bottom;
    if (isTopOut === isBottomOut) {
      if (callback) {
        callback();
      }
      // don't scroll
      return;
    }

    const targetScrollTopDif = isTopOut ? elBounds.top - scrollerBounds.top - opt.offset : (elBounds.top + elementToScroll.offsetHeight) - scrollerBounds.bottom + opt.offset;
    const targetScrollTop = scrollerEl.scrollTop + targetScrollTopDif;
    scrollToWithAnimation(scrollerEl, targetScrollTop, opt.duration, easeInCubic, callback);
  }
}

export function getElementScrollbarWidth(element: HTMLElement): number {
  return element ? element.offsetWidth - element.clientWidth : 0;
}

export function getScrollbarWidth() {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = 'scroll';

  // add innerdiv
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  if (outer.parentNode) {
    outer.parentNode.removeChild(outer);
  }

  return widthNoScroll - widthWithScroll;
}


const _eventHandlers: any = {}; // event handlers registrations

export function addListener(node: any, events: Array<string>, handler: EventListenerOrEventListenerObject, capture: boolean = false) {
  if (!(node in _eventHandlers)) {
    // _eventHandlers stores references to nodes
    _eventHandlers[node] = {};
  }
  events.forEach(event => {
    if (!(event in _eventHandlers[node])) {
      // each entry contains another entry for each event type
      _eventHandlers[node][event] = [];
    }
    // capture reference
    _eventHandlers[node][event].push([handler, capture]);
    node.addEventListener(event, handler, capture);
  });
}

export function removeAllListeners(node: any, events: Array<string>) {
  if (node && node in _eventHandlers) {
    const handlers = _eventHandlers[node];
    events.forEach(event => {
      if (event in handlers) {
        const eventHandlers = handlers[event];
        for (let i = eventHandlers.length; i--;) {
          const handler = eventHandlers[i];
          node.removeEventListener(event, handler[0], handler[1]);
        }
        delete handlers[event];
      }
    });
    if (!Object.keys(handlers).length) {
      delete _eventHandlers[node];
    }
  }
}

export function getElementOffset(nativeEl: HTMLElement): IElementPositionInfo {
  const boundingClientRect = nativeEl.getBoundingClientRect();
  return {
    width: boundingClientRect.width || nativeEl.offsetWidth,
    height: boundingClientRect.height || nativeEl.offsetHeight,
    top: boundingClientRect.top + (window.pageYOffset || document.documentElement.scrollTop),
    left: boundingClientRect.left + (window.pageXOffset || document.documentElement.scrollLeft)
  };
}

/**
 * Provides read-only equivalent of jQuery's position function:
 * http://api.jquery.com/position/
 */
export function getElementPosition(nativeEl: any): IElementPositionInfo {
  const elBCR = getElementOffset(nativeEl);
  let offsetParentBCR = {top: 0, left: 0};
  const offsetParentEl = parentOffsetEl(nativeEl);
  if (offsetParentEl !== document) {
    offsetParentBCR = getElementOffset(offsetParentEl);
    offsetParentBCR.top += offsetParentEl.clientTop - offsetParentEl.scrollTop;
    offsetParentBCR.left += offsetParentEl.clientLeft - offsetParentEl.scrollLeft;
  }

  const boundingClientRect = nativeEl.getBoundingClientRect();
  return {
    width: boundingClientRect.width || nativeEl.offsetWidth,
    height: boundingClientRect.height || nativeEl.offsetHeight,
    top: elBCR.top - offsetParentBCR.top,
    left: elBCR.left - offsetParentBCR.left
  };
}


export function matchesSelector(el: any, selector: string) {
  return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
}


export function parseHTML(content: string): HTMLDocument {
  const tmp = document.implementation.createHTMLDocument();
  tmp.body.innerHTML = content;
  return tmp;
}


const isIE = !!((document.documentElement as any).currentStyle);

const HIDDEN_TEXTAREA_STYLE: any = {
  'min-height': '0',
  'max-height': 'none',
  height: '0',
  visibility: 'hidden',
  overflow: 'hidden',
  position: 'absolute',
  'z-index': '-1000',
  top: '0',
  right: '0',
};

const SIZING_STYLE = [
  'letter-spacing',
  'line-height',
  'font-family',
  'font-weight',
  'font-size',
  'font-style',
  'tab-size',
  'text-rendering',
  'text-transform',
  'width',
  'text-indent',
  'padding-top',
  'padding-right',
  'padding-bottom',
  'padding-left',
  'border-top-width',
  'border-right-width',
  'border-bottom-width',
  'border-left-width',
  'box-sizing',
];

const computedStyleCache: any = {};
const hiddenTextarea = document.createElement('textarea');

const forceHiddenStyles = (node: any) => {
  Object.keys(HIDDEN_TEXTAREA_STYLE).forEach((key: any) => {
    node.style.setProperty(key, HIDDEN_TEXTAREA_STYLE[key], 'important');
  });
};

export function calculateNodeHeight(
  uiTextNode: any,
  uid: string,
  useCache = false,
  minRows: number | null = null,
  maxRows: number | null = null,
) {
  if (hiddenTextarea.parentNode === null) {
    document.body.appendChild(hiddenTextarea);
  }

  // Copy all CSS properties that have an impact on the height of the content in
  // the textbox
  const nodeStyling = calculateNodeStyling(uiTextNode, uid, useCache);

  if (nodeStyling === null) {
    return null;
  }

  const {paddingSize, borderSize, boxSizing, sizingStyle} = nodeStyling;

  // Need to have the overflow attribute to hide the scrollbar otherwise
  // text-lines will not calculated properly as the shadow will technically be
  // narrower for content
  Object.keys(sizingStyle).forEach((key: any) => {
    hiddenTextarea.style[key] = sizingStyle[key];
  });
  forceHiddenStyles(hiddenTextarea);
  hiddenTextarea.value = uiTextNode.value || uiTextNode.placeholder || 'x';

  let minHeight = -Infinity;
  let maxHeight = Infinity;
  let height = hiddenTextarea.scrollHeight;

  if (boxSizing === 'border-box') {
    // border-box: add border, since height = content + padding + border
    height = height + borderSize;
  } else if (boxSizing === 'content-box') {
    // remove padding, since height = content
    height = height - paddingSize;
  }

  // measure height of a textarea with a single row
  hiddenTextarea.value = 'x';
  const singleRowHeight = hiddenTextarea.scrollHeight - paddingSize;

  // Stores the value's rows count rendered in `hiddenTextarea`,
  // regardless if `maxRows` or `minRows` props are passed
  const valueRowCount = Math.floor(height / singleRowHeight);

  if (minRows !== null) {
    minHeight = singleRowHeight * minRows;
    if (boxSizing === 'border-box') {
      minHeight = minHeight + paddingSize + borderSize;
    }
    height = Math.max(minHeight, height);
  }

  if (maxRows !== null) {
    maxHeight = singleRowHeight * maxRows;
    if (boxSizing === 'border-box') {
      maxHeight = maxHeight + paddingSize + borderSize;
    }
    height = Math.min(maxHeight, height);
  }

  const rowCount = Math.floor(height / singleRowHeight);

  return {height, minHeight, maxHeight, rowCount, valueRowCount};
}

function calculateNodeStyling(node: any, uid: string, useCache = false) {
  if (useCache && computedStyleCache[uid]) {
    return computedStyleCache[uid];
  }

  const style = window.getComputedStyle(node);

  if (style === null) {
    return null;
  }

  const sizingStyle: any = SIZING_STYLE.reduce((obj: any, name) => {
    obj[name] = style.getPropertyValue(name);
    return obj;
  }, {});

  const boxSizing = sizingStyle['box-sizing'];

  // probably node is detached from DOM, can't read computed dimensions
  if (boxSizing === '') {
    return null;
  }

  // IE (Edge has already correct behaviour) returns content width as computed width
  // so we need to add manually padding and border widths
  if (isIE && boxSizing === 'border-box') {
    sizingStyle.width =
      parseFloat(sizingStyle.width) +
      parseFloat(style['border-right-width']) +
      parseFloat(style['border-left-width']) +
      parseFloat(style['padding-right']) +
      parseFloat(style['padding-left']) +
      'px';
  }

  const paddingSize =
    parseFloat(sizingStyle['padding-bottom']) +
    parseFloat(sizingStyle['padding-top']);

  const borderSize =
    parseFloat(sizingStyle['border-bottom-width']) +
    parseFloat(sizingStyle['border-top-width']);

  const nodeInfo = {
    sizingStyle,
    paddingSize,
    borderSize,
    boxSizing,
  };

  if (useCache) {
    computedStyleCache[uid] = nodeInfo;
  }

  return nodeInfo;
}

export const purgeCache = (uid: any) => {
  delete computedStyleCache[uid];
};
