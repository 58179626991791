export class Config {
    public static isProd: boolean = process.env.ISPROD as any;
    private static _data: any = process.env.CLIENT_CONFIG; // loaded from project metadata

    public static setConfigKey(key: string) {
        Config._data = process.env[key];
    }

    public static get<T>(key: string, defaultValue: any = null): T {
        if (!Config._data) {
            throw new Error('Configuration was not set yet!');
        }
        return Config._data.hasOwnProperty(key) ? Config._data[key] : defaultValue;
    }

    public static set(key: string, value: any) {
        Config._data[key] = value;
    }
}
